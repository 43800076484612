/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import 'tippy.js/dist/tippy.css';
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';

@import '~@ng-select/ng-select/themes/default.theme.css';

@import 'highlight.js/styles/default.css';

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

a {
  cursor: pointer;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
}

h3 {
  font-size: 1.2rem;
}

// body {
//     -webkit-user-select: text;
//     -moz-user-select: text;
//     -ms-user-select: text;
//     user-select: text;
// }

ion-content {
  --background: var(--ion-light-gray-bg);
}

ion-header,
ion-footer {
  background: #ffffff;
}

ion-title {
  font-weight: 600;
  font-size: 0.85rem;
}

ion-button {
  text-transform: none;
  letter-spacing: 0px;
  font-weight: 600;
  font-size: 0.85rem;
}

ion-list ion-checkbox {
  margin: 0 12px 0 0;
}

.button-round {
  --padding-start: 15px;
  --padding-end: 15px;
}

.btn-large {
  font-size: 1rem;
  height: 48px;
}

.btn-small {
  font-size: 0.75rem;
  height: 30px;
}

ion-card,
.ion-card {
  color: inherit;
  box-shadow: 0px 1px 4px 0px #a9b0b633;
  border-radius: 8px;
  background: #ffffff;
}

ion-card-subtitle {
  text-transform: none;
  letter-spacing: normal;
  font-weight: 400;
}

.outline-card {
  box-shadow: 0px 0px 0px 1px #c7cfd633;
}

.content-top-shadow {
  width: 100%;
  top: 0;
  box-shadow: 0px 0px 1px 1px #c7cfd633;
  position: fixed;
  z-index: 99;
}

ion-searchbar {
  .searchbar-input-container {
    .searchbar-input {
      font-size: 14px !important;
      background: #ffffff;
      border-radius: 5px !important;
    }

    .searchbar-search-icon {
      left: 7px;
      width: 18px;
    }
  }
}

ion-icon {
  vertical-align: middle;
}

ion-item {
  --border-color: var(--ion-color-step-50);
  font-size: 0.9rem;
  --background-focused-opacity: 0.06;
  --background-hover-opacity: 0.02;
  background: transparent;
  --background: transparent;
}

ion-item:last-child {
  --border-width: 0;
  --border-color: transparent;
}

ion-badge {
  border-radius: 9999px;
  padding: 5px 10px;
  font-weight: 400;
}

ion-card-title {
  font-weight: 700;
  font-size: 1.1rem;
}

ion-fab-button {
  --box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2);
}

.popover-viewport {
  overflow: auto !important;
}

.pill-button-active {
  background: rgba(var(--ion-color-primary-rgb), 0.09);
  border-radius: var(--input-border-radius);
}

.header-md:after {
  background-position: left 0 top -5px;
}

.constrained-content {
  max-width: 1120px;
  margin-left: auto;
  margin-right: auto;
}

.no-padding {
  padding: 0;
}

.grid-no-padding {
  padding: 0;

  ion-row {
    padding: 0;

    ion-col {
      padding: 0;
    }
  }
}

.transparent-bg {
  background: transparent;
  --background: transparent;
}

.button-solid {
  --box-shadow: none;
}

.add-caret {
  font-size: 0.7rem;
  padding-left: 5px;
  opacity: 0.7;
}

.mobile-menu-toggle {
  margin: 10px 0 -10px 5px;
}

.depthButton {
  cursor: pointer;
  transition: all 0.15s ease;
}

.depthButton:hover {
  transform: translateY(-1px);
  box-shadow: 0px 2px 6px 0px #787d813d;
}

.depthButton:active {
  color: #5778f3;
  transform: translateY(0px);
  box-shadow: 0px 1px 1px 0px #a9b0b63b;
}

.light-shadow {
  box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 16%);
}

.deep-shadow {
  box-shadow: 0px 3px 12px 0px rgb(0 0 0 / 16%);
}

.editable-title:hover {
  color: var(--ion-color-primary);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.15);
}

.padded {
  padding: 10px;
}

.clickable {
  cursor: pointer;
}

.text-superbold {
  font-weight: 700;
}

.text-superbig {
  font-size: 3.2rem;
}

.code {
  font-family: monospace;
  color: #212101;
  background: #f0f0f0;
  border: 1px solid #e9e6e6;
  border-radius: 5px;
  padding: 2px;
}

.top-searchbar {
  ion-searchbar {
    height: 54px;
  }

  ion-button {
    margin: 9px;
  }
}

.plan-card {
  margin: 7px 14px;
  cursor: pointer;

  ion-card-title {
    margin-bottom: 10px;
    margin-top: 2px;
    font-weight: 700;
  }

  ion-card-subtitle {
    font-size: 0.9rem;
  }
}

.plan-card-image {
  width: 100%;
  height: 186px;
  object-fit: cover;
}

.plan-date-header {
  font-size: 1.1rem;
  margin-left: 15px;
  margin-bottom: 15px;
  color: #333;
}

.event-card-large {
  display: flex;
  flex-direction: row;

  ion-card-content {
    padding-inline-start: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  ion-card-header {
    padding: 10px 16px;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: flex-start;

    ion-card-title {
      margin-bottom: 10px;
    }

    ion-card-subtitle {
      font-size: 1rem;
      margin-bottom: 5px;
    }
  }

  .event-card-photo {
    width: 36%;
    height: 100%;
    max-height: 220px;
    border-radius: 4px;
    object-fit: cover;
  }
}

.icon-padded,
.padded-icon {
  padding-right: 8px;
}

.icon-padded-left,
.padded-icon-left {
  padding-left: 8px;
}

.text-with-left-icon {
  margin-left: 7px;
}

.text-with-right-icon {
  margin-right: 7px;
}

.input-label {
  font-size: 0.8rem;
  opacity: 0.8;
}

.input-field {
  margin-top: 6px;
  margin-bottom: 12px;
  border: 2px solid var(--input-border-color);
  border-radius: var(--input-border-radius);
  color: #000000;
  background: #ffffff;
  --padding-start: 8px;
  --padding-end: 8px;
}

.select-outline {
  border: 2px solid var(--input-border-color);
  border-radius: var(--input-border-radius);
}

.has-focus {
  border-color: var(--ion-color-primary);
}

.fullscreen-spinner-container {
  padding-top: 38vh;
  text-align: center;
  background: #ffffffb0;
  height: 100%;
  width: 100%;
}

.fullscreen-spinner {
  color: #999999;
  font-weight: 500;
}

.info-tooltip {
  color: var(--ion-color-primary);
  cursor: pointer;
}

.big-title-input {
  margin-bottom: 19px;
  border-bottom: 2px solid var(--input-border-color);
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;

  input {
    --padding-left: 0px;
  }
}

.text-link {
  text-decoration: none;
}

.top-preview-bar {
  background: #e2fcde;
  color: #1c6411;
  text-align: center;
  padding: 15px;
  font-size: 0.8rem;
}

/*
 * Padding utils
 */
.padded-10 {
  padding: 10px;
}

.padded-15 {
  padding: 15px;
}

.padded-20 {
  padding: 20px;
}

/*
 * Flexbox
 */
.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.inline-flexed {
  display: inline-flex;
}

.inline-flexed-align-center {
  display: inline-flex;
  align-items: center;
}

.flexed {
  display: flex;
}

.flexed-align-center {
  display: flex;
  align-items: center;
}

/*
 * Modals
 */
.modal-title {
  margin-left: 16px;
  display: flex;

  h3 {
    margin-top: 0;
  }

  .subtitle {
    margin: 6px 15px;
    opacity: 0.5;
    font-size: 0.75rem;
    font-weight: 500;
  }
}

.modal-large {
  &::part(content) {
    --min-width: 70vw;
    --min-height: 85vh;
  }
}

/*
 * Table
 */
.ngx-datatable.material {
  box-shadow: none;
  height: 100%;

  .empty-row {
    opacity: 0.2;
    padding: 1rem 1.2rem 1.2rem;
  }
}

.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background-color: unset;
}

.engagement-status-column {
  padding: 10px !important;
}

/*
 * Post item
 */
.post-item {
  ion-card-header {
    font-size: 1.1rem;
    padding-bottom: 0;
  }

  ion-card-subtitle {
    font-weight: 400;
    color: #454545;
  }

  ion-avatar {
    margin-right: 10px;
  }
}

/*
 * Reports & Analytics
 */
.report-card {
  padding: 15px;
}

.report-title {
  font-weight: 600;
  margin-top: 8px;
  margin-left: 10px;
  margin-bottom: 5px;
  color: #666;
}

.report-stat {
  margin-left: 10px;
  margin-bottom: 3px;
  font-weight: 600;
  color: #000;
  font-size: 2.4rem;
}

.notifications-popover {
  &::part(content) {
    width: 354px;
  }
}

/*
 * Left nav
 */
.left-nav-menu {
  border-inline-end: 1px solid var(--ion-color-step-100);
}

.left-nav-back-button {
  font-size: 1.3rem;
  margin-left: 15px;
}

.left-nav-title {
  font-weight: 700;
  margin-left: 15px;
  font-size: 1.2rem;
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --background: #ffffff;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-item {
  --padding-start: 8px;
  --padding-end: 10px;
  border-radius: 999px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.07);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 600;
  font-size: 0.9rem;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}

/*
 * Nav bar for admins
 */
.admin-bar {
  padding: 10px;
  background: #f9fdf4;
  color: #567929;
  font-size: 0.8rem;
}

.admin-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/*
 * Tab nav (instead of segments)
 */
.tab-nav-link {
  cursor: pointer;
  padding: 20px 0;
  margin: 0 15px;
  font-size: 0.85rem;
  font-weight: 600;
  transition: all 0.15s ease;
  color: #646464;
}

.tab-nav-link:hover,
.tab-nav-active {
  color: var(--ion-color-primary);
  box-shadow: inset 0px -2px 0px 0px var(--ion-color-primary);
}

.tab-nav-badge {
  vertical-align: middle;
  padding: 4px 8px;
  margin-left: 5px;
}

/*
 * Left tab / inbox filters
 */
.inbox-list {
  border-radius: 4px;
  padding: 0px;

  ion-item {
    cursor: pointer;
    --padding-start: 10px;
    --padding-end: 10px;
    border-radius: 4px;
    --min-height: 44px;
    --background-hover: rgba(var(--ion-color-primary-rgb), 0.09);
  }

  ion-item:hover {
    ion-icon {
      --color: var(--ion-color-primary);
      color: var(--ion-color-primary);
    }
  }

  .selected ion-icon {
    color: var(--ion-color-primary);
  }

  ion-item ion-icon {
    color: #616e7e;
    margin: 0 12px 0 8px;
    font-size: 1.2rem;
  }

  ion-item ion-label {
    font-weight: 500;
    font-size: 0.9rem;
  }

  .selected {
    --color: var(--ion-color-primary);
    --background: rgba(var(--ion-color-primary-rgb), 0.09);
  }
}

/*
 * Popovers
 */
ion-popover::part(content) {
  width: 305px;
  --box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.16);
}

/*
 * Empty state
 */
.empty-state-container {
  height: 90%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .placeholder-emoji {
    opacity: 0.2;
    font-size: 8rem;
  }

  .placeholder-title {
    opacity: 0.6;
  }

  .placeholder-subtitle {
    opacity: 0.3;
  }
}

/*
 * Angular Editor
 */
.angular-editor-toolbar {
  border: none !important;
  background: var(--ion-color-step-50) !important;
  margin: 3px 3px 0 3px;
  padding: 3px;
  border-radius: 5px;
}

.angular-editor-button {
  background: var(--ion-color-step-50) !important;
  border: none !important;
}

.angular-editor-textarea {
  border: none !important;
}

/*
 * Tippy
 */
@media (max-width: 540px) {
  .tippy-popper {
    display: none;
  }
}

/*
 * Intercom
 */
@media (max-width: 540px) {
  .intercom-lightweight-app {
    display: none;
  }
}

/*
 * Responsive utilities
 */

.shown-sm {
  display: none;
}

.shown-md {
  display: none;
}

.shown-lg {
  display: none;
}

@media (max-width: 1240px) {
  .hidden-xl {
    display: none;
  }
}

@media (max-width: 960px) {
  .hidden-lg {
    display: none;
  }

  .shown-lg {
    display: inline-block;
  }
}

@media (min-width: 960px) {
  .left-nav-menu {
    max-width: 270px;
  }
}

@media (max-width: 720px) {
  .hidden-md {
    display: none;
  }

  .shown-md {
    display: inline-block;
  }
}

@media (max-width: 540px) {
  .hidden-sm {
    display: none;
  }

  .shown-sm {
    display: inline-block;
  }
}

.ng-select.ng-select-multiple.autocomplete-override .ng-select-container .ng-value-container .ng-placeholder {
  top: initial;
  padding-bottom: 0;
}

.ng-select.ng-select-multiple.autocomplete-override .ng-select-container {
  border: none;
}

.ng-select.ng-select-multiple.autocomplete-override .ng-select-container .ng-value-container .ng-value {
  display: flex;
  flex-direction: row-reverse;
  border-radius: 16px;
  border-color: #bbb;
  background: transparent;
  border-width: 1px;
  border-style: solid;
  padding: 7px 9px;
  margin: 4px;
  font-family: var(--ion-font-family, inherit);
  font-size: 14px;
  line-height: 1;
}

.ng-select.ng-select-multiple.autocomplete-override .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right: none;
  border-radius: 32px;
  color: white;
  background-color: black;
  width: 16px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding: 5px 0;
}

.ng-select .ng-select-container:hover {
  box-shadow: none;
}

.cursor-based-paging .pager {
  li:first-child {
    display: none !important;
  }

  li:last-child {
    display: none !important;
  }

  li.pages {
    display: none !important;
  }
}

.ion-chip-accessory-button {
  background: #c9d7ee;
  color: var(--ion-color-primary);
  padding: 4px;
  margin-left: 8px;
  margin-right: -6px;
  border-radius: 999px;
}

.service-account-token-modal {
  .alert-wrapper {
    max-width: 600px;
    user-select: text;
  }
}

.admin-settings-warning-modal {
  .alert-wrapper {
    max-width: 600px;
    user-select: text;
  }
}

.upgrade-feature-banner {
  background: #7257cc;
  background-image: linear-gradient(135deg, #924cff, #6583ff);
  color: #ffffff;
  padding: 10px 20px;
  align-items: center;
  font-size: 0.85rem;
}

/* The switch - the box around the slider */
.switch {
  font-size: 12px;
  position: relative;
  display: inline-block;
  width: 3.5em;
  height: 2em;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border: 1px solid #adb5bd;
  transition: 0.4s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 1.4em;
  width: 1.4em;
  border-radius: 20px;
  left: 0.27em;
  bottom: 0.25em;
  background-color: #adb5bd;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #007bff;
  border: 1px solid #007bff;
}

input:focus+.slider {
  box-shadow: 0 0 1px #007bff;
}

input:checked+.slider:before {
  transform: translateX(1.4em);
  background-color: #fff;
}
